<template>
    <div class="login_main">
        <div class="header">
            <img src="../../assets/img/shop_logo.png" class="logo" @click="$router.replace('/index')" />
        </div>

        <div class="main">
            <div class="content">
                <img :src="backendImage ? imageURL + backendImage : require('../../assets/img/login_img.png')" class="img" />

                <div class="form">
                    <form >
                        <div class="formItem">
                            <div class="inputItem">
                                <div class="icon">
                                    <img src="../../assets/img/login_phone.png" width="16" height="20" />
                                </div>
                                <input v-model="form.phone" autocomplete="off" placeholder="请输入您的手机号" class="formInput" @keyup.enter="goVerifyCode" />
                                <input style="position: fixed; z-index: -999999"/>
                            </div>
                        </div>

                        <div class="formItem">
                            <div class="inputItem">
                                <div class="icon">
                                    <img src="../../assets/img/login_code.png" width="16" height="20" />
                                </div>
                                <input type="password"  style="position: fixed; z-index: -999999; width: 0; height: 0"/>
                                <input v-model="form.imgCode" autocomplete="imgCode" placeholder="请输入图形验证码" class="formInput" @keyup.enter="goVerifyCode" />
                                <input style="position: fixed; z-index: -999999; width: 0; height: 0"/>
                            </div>
                            <img :src="codeImage" class="codeBtn" @click="goImgCode" style="cursor: pointer" />
                        </div>

                        <div class="formItem">
                            <div class="inputItem">
                                <div class="icon">
                                    <img src="../../assets/img/login_code.png" width="16" height="20" />
                                </div>
                                <input type="password"  style="position: fixed; z-index: -999999; width: 0; height: 0"/>
                                <input v-model="form.code" autocomplete="off" placeholder="请输入验证码" class="formInput" @keyup.enter="goVerifyCode" />
                                <input style="position: fixed; z-index: -999999; width: 0; height: 0" />
                            </div>
                            <el-button :class="isSendCode ? 'on' : ''" class="codeBtn" :loading="isRequestSend" @click="toSendCode">{{isSendCode ? smsBtnText : '获取验证码'}}</el-button>
                        </div>

                        <div class="formItem">
                            <div class="inputItem">
                                <div class="icon">
                                    <img src="../../assets/img/login_password.png" width="16" height="20" />
                                </div>
                                <input style="position: fixed; z-index: -999999" type="password" />
                                <input v-model="form.password" autocomplete="off" type="password" placeholder="请设置密码（8-20位，包含字母和数字）" class="formInput" @keyup.enter="goVerifyCode" />
                            </div>
                        </div>

                        <div class="formItem">
                            <div class="inputItem">
                                <div class="icon">
                                    <img src="../../assets/img/login_password.png" width="16" height="20" />
                                </div>
                                <input style="position: fixed; z-index: -999999" type="password" />
                                <input v-model="form.passwordSure" autocomplete="off" type="password" placeholder="请确认密码" class="formInput" @keyup.enter="goVerifyCode" />
                            </div>
                        </div>



                        <div class="formItem">
                            <el-button type="primary" class="loginBtn" :loading="isRequestLoading" @click="goVerifyCode">立即修改</el-button>
                        </div>
                    </form>

                    <div class="else">
                        <div style="flex: 1"></div>
                        <div class="text"><a href="javascript:void(0)" @click="$router.replace('/login')">立即登录</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {smsSend, smsVerify, postUserForget, postImgCode, getBackendLogin} from '../../api/public'
  import {regular_phone, regular_password} from '../../utils/regular'
  import {baseURL, imageURL} from "../../utils/config";
  export default {
    name: "Forget",
    data () {
      return {
        imageURL: imageURL,
        form: {
          phone: '',
          imgCode: '',
          code: '',
          password: '',
          passwordSure: ''
        },
        isRequestLoading: false,
        isRequestSend: false,
        isSendCode: false,
        checked: false,
        smsBtnText: '',
        codeNum: 0,
        codeImage: '',
        dateTimes: null,
        backendImage: ''
      }
    },
    created() {
      this.dateTimes = new Date().getTime();
      this.goImgCode();
      this.getBackendLogin()
    },
    methods: {
      goImgCode () {
        this.codeNum++
        this.codeImage = `${baseURL}/kaptcha/getImageCode/${this.dateTimes}?type=${this.codeNum}`
      },
      getBackendLogin () {
        getBackendLogin().then(res => {
          if (res.code === 0) {
            this.backendImage = res.data.fileName
          }
        })
      },
      toSendCode () {
        if (this.isSendCode) {
          return false
        }
        if (this.form.phone === '') {
          this.$message({
            message: '请输入您的手机号',
            showClose: true,
            type: 'warning'
          })
          return false
        }
        if (this.form.imgCode === '') {
          this.$message({
            message: '请输入图形验证码',
            showClose: true,
            type: 'warning'
          })
          return false
        }
        if (!regular_phone.test(this.form.phone)) {
          this.$message({
            message: '请输入正确的手机号',
            showClose: true,
            type: 'warning'
          })
          return false
        }
        postImgCode({
          data: {
            code: this.form.imgCode,
            uuid: this.dateTimes
          }
        }).then(res => {
          if (res.code === 0) {
            this.getCode()
          } else {
            this.goImgCode()
          }
        }).catch(() => {
          this.goImgCode()
        })
      },

      getCode () {
        this.isRequestSend = true
        smsSend({
          data: {
            phone: this.form.phone,
            type: 4
          }
        }).then(res => {
          this.isRequestSend = false
          if(res.code === 0) {
            this.isSendCode = true
            this.toChangeSmsTime(60)
          }
        }).catch(() => {
          this.isRequestSend = false
        })
      },

      toChangeSmsTime (time) {
        if (time === 0) {
          this.isSendCode = false
        } else {
          this.isSendCode = true
          this.smsBtnText = '重新获取(' + time + 's)'
          let times = time - 1
          let that = this
          setTimeout(() => {
            that.toChangeSmsTime(times)
          }, 1000)
        }
      },

      goVerifyCode () {
        if (this.form.phone === '') {
          this.$message({
            message: '请输入您的手机号',
            showClose: true,
            type: 'warning'
          })
          return false
        }
        if (this.form.imgCode === '') {
          this.$message({
            message: '请输入图形验证码',
            showClose: true,
            type: 'warning'
          })
          return false
        }
        if (this.form.code === '') {
          this.$message({
            message: '请输入验证码',
            showClose: true,
            type: 'warning'
          })
          return false
        }
        if (this.form.password === '') {
          this.$message({
            message: '请设置密码',
            showClose: true,
            type: 'warning'
          })
          return false
        }
        if (!regular_password.test(this.form.password)) {
          this.$message({
            message: '请设置密码（8-20位，包含字母和数字）',
            showClose: true,
            type: 'warning'
          })
          return false
        }

        if (this.form.password !== this.form.passwordSure) {
          this.$message({
            message: '两次输入的密码不一致',
            showClose: true,
            type: 'warning'
          })
          return false
        }

        this.isRequestLoading = true
        smsVerify({
          data: {
            phone: this.form.phone,
            type: 4,
            code: this.form.code
          }
        }).then(res => {
          if (res.code === 0) {
            this.toRegister()
          } else {
            this.isRequestLoading = false
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },

      toRegister () {
        postUserForget({
          data: {
            phone: this.form.phone,
            password: this.form.password
          }
        }).then(res => {
          this.isRequestLoading = false
          if (res.code === 0) {
            this.$message({
              message: '密码修改成功',
              showClose: true,
              type: 'success'
            })
            this.$router.replace('/login')
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      }
    }
  }
</script>
